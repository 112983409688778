@import "variables";

// Reset: begin
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  width: 100%;
  max-width: $breakpoint-max;
  min-width: 320px;
  min-height: 100vh;
  overflow-x: hidden;
  margin: 0 auto;
  padding: 0;
  color: $color-dark;
  background: $color-light;
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: $font-lineheight-base;
  font-weight: $font-weight-base;
}

a {
  user-select: none;
  color: inherit;
  text-decoration: none;
}

button {
  user-select: none;
  cursor: pointer;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  background: transparent;
  padding: 0;
  border: 0;

  &[disabled] {
    cursor: not-allowed;
  }
}

img {
  font-size: 8px;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

input,
select,
option {
  font-family: inherit;

  &:focus {
    outline: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

b,
strong {
  font-weight: $font-weight-semibold;
}

*::-webkit-scrollbar {
  @include media-up($breakpoint-tablet) {
    height: 4px;
    width: 4px;
  }
}

*::-webkit-scrollbar-track {
  @include media-up($breakpoint-tablet) {
    background: rgba($color-black, 0.04);
  }
}

*::-webkit-scrollbar-thumb {
  @include media-up($breakpoint-tablet) {
    background: rgba($color-black, 0.08);
    cursor: pointer;
  }
}

div,
nav,
ul {
  @include media-up($breakpoint-tablet) {
    scrollbar-color: rgba($color-black, 0.08) rgba($color-black, 0.04);
    scrollbar-width: thin;
  }
}

*::selection {
  color: $color-white;
  background: $color-black;
}
// Reset: end
