.root {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    to top,
    #f0f0f0,
    #f1f1f1,
    #f2f3f3,
    #f3f4f4,
    #f4f5f4,
    #f4f5f5,
    #f5f5f5,
    #f5f5f5,
    #f5f5f5,
    #f4f4f4,
    #f4f4f4,
    #f3f3f3
  );
}

.logo {
  width: 260px;
  display: flex;
  flex-flow: column;

  &Caption {
    font-size: 18px;
    line-height: 1;
    font-weight: 200;
    letter-spacing: 3.6px;
    width: 100%;
    text-align: center;
    transform: translateX(1px);
    margin: 5px 0 0;
  }
}

.contact {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;

  &Email {
    display: flex;
    flex-flow: row;
    align-items: center;
    font-size: 15px;
    font-weight: 400;
    gap: 0 0.2px;

    &At {
      transform: translateY(1px);
    }
  }

  &Eesti {
    width: 20px;
    height: 20px;
  }
}
