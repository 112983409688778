// media
@mixin media-down($width) {
  @media only screen and (max-width: #{$width}) {
    @content;
  }
}

@mixin media-up($width) {
  @media only screen and (min-width: #{$width}) {
    @content;
  }
}
