// breakpoints
$breakpoint-min: 320px;
$breakpoint-mobile: 544px;
$breakpoint-tablet: 768px;
$breakpoint-minibook: 1024px;
$breakpoint-laptop: 1232px;
$breakpoint-pc: 1440px;
$breakpoint-max: 3840px;

// colors
$color-black: #000;
$color-white: #fff;
$color-dark: #1e1e1e;
$color-light: #f8f8f8;
$color-green: #32c682;
$color-red: #ff5549;
$color-yellow: #eebf31;
$color-blue: #26c0d3;

// transitions
$transition-base: all 0.25s ease-in-out;
$transition-transform: transform 0.25s linear;
$transition-opacity: opacity 0.25s ease-in-out;
$transition-opacity: opacity 0.25s ease-in-out;
$transition-visibility: visibility 0.25s ease-in-out;
$transition-background: background 0.25s ease-in-out;
$transition-boxshadow: box-shadow 0.25s ease-in-out;
$transition-color: color 0.25s ease-in-out;
$transition-width: width 0.25s ease-in-out;
$transition-height: height 0.25s ease-in-out;
$transition-left: left 0.25s ease-in-out;
$transition-top: top 0.25s ease-in-out;
$transition-right: right 0.25s ease-in-out;
$transition-bottom: bottom 0.25s ease-in-out;

// typography
$font-family-base: "Outfit", sans-serif;
$font-size-base: 14px;
$font-lineheight-base: 1.2;
$font-weight-base: 400;
$font-weight-thin: 100;
$font-weight-extralight: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extrabold: 800;
$font-weight-black: 900;

// mixins
@import "mixins";

// THEME
$color-sapiens-dark: #1e1e1e;
$color-sapiens-green-primary: #2ebfd3;
$color-sapiens-green-secondary: #2592a1;
$color-gray: #505050;
